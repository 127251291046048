.formEntry {
  position: relative;
  margin-bottom: 1.5em;

  .label {
    margin-bottom: 0;
  }

  .textarea {
    font-size: 1.25em;
    width: 100%;
    height: 100px;

    &::placeholder{
      font-size: 0.75em;
      padding-left: 0.5em;
    }

    &.error {
      border: 1px solid red;
    }
  }

  .errorMessage {
    position: absolute;
    bottom: -1.25em;
    margin-bottom: 0;
    color: red;
  }
}
