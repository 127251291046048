a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

p {
  margin: 0 0 1em 0;
}

button {
  font-size: inherit;
}
