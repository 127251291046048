$smartphone-max: 767px;
$tablen-min: 768px;
$tablet-max: 979px;
$desktop-min: 980px;
$desktop-max: 1259px;
$desktop-large-min: 1260px;

@mixin smartphone-view { @media (max-width: $smartphone-max) { @content } }
@mixin tablet-view { @media (min-width: $tablen-min) and (max-width: $tablet-max) { @content } }
@mixin desktop-view { @media (min-width: $desktop-min) and (max-width: $desktop-max) { @content } }
@mixin desktop-large-view { @media (min-width: $desktop-large-min) { @content } }

@mixin tablet-view-and-up { @media (min-width: $smartphone-max) { @content } }
@mixin desktop-view-and-up { @media (min-width: $desktop-min) { @content } }

@mixin tablet-view-and-down { @media (max-width: $tablet-max) { @content } }
@mixin desktop-view-and-down { @media (max-width: $desktop-max) { @content } }
