@import "../assets/colors";
@import "../assets/mixins";

.header {
  display: flex;
  background-color: $colorPrimary;
  height: 80px;
  min-height: 80px;
  align-items: center;
  padding: 0 3em;
  border-bottom: 2px solid $colorSecondary;

  @include tablet-view-and-down{
    padding: 0 1em;
  }

  .desktopNav {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
    white-space: nowrap;

    .link {
      padding: 0 1em;
      display: flex;
      color: black;
      text-decoration: none;
      margin: auto;
      height: 100%;
      box-sizing: border-box;
      transition: background-color 100ms linear;

      &:hover {
        background-color: $colorPrimaryLight;
      }

      &.active {
        background-color: $colorPrimaryDark;
      }

      .linkText {
        margin: auto;
      }
    }

    @include tablet-view-and-down {
      display: none;
    }
  }

  .menuButton {
    outline: none;
    margin-left: auto;
    margin-top: 5px;

    display: none;

    @include tablet-view-and-down {
      display: block;
    }
  }

  .logoLink {
    height: 80%;

    @include desktop-view-and-down{
      height: 70%;
    }

    .logo {
      height: 100%;
    }
  }

  .companyName {
    font-size: 2em;
    margin-left: 2em;
    font-weight: 500;
    font-family: 'Alegreya SC', serif;

    @include desktop-view-and-down {
      font-size: 1.5em;
      margin-left: 1em;
    }

    @include smartphone-view {
      display: none
    }
  }
}

.mobileNav {
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  align-items: center;
  max-height:0;
  width: 100%;

  background-color: $colorPrimary;

  @include tablet-view-and-down {
    &.showMobile {
      transition: 250ms linear;
      max-height: 80vh;
    }
  }

  .link {
    display: block;
    width:100%;
    padding: 0.5em 0;
    text-align: center;
    border-bottom: 1px solid $colorGrey4;

    &.active {
      background-color: $colorPrimaryDark;
    }
  }
}
