@import "src/assets/mixins";

.form {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1em;
  margin-left: -0.5em;
  margin-right: -0.5em;;

  .formSection {
    margin-bottom: 0;
    flex-basis: 42%;
    margin-right: 0.5em;
    margin-left: 0.5em;
    min-width: 13em;
    flex-grow: 1;

    &.marginBottom {
      margin-bottom: 2em;
    }

    label {
      margin-right: 0.5em;
    }

    h4 {
      margin-bottom: 0.5em;
    }

    .otherInfoInput {
      width: 100%;
      height: 100px;
      resize: vertical;
    }
  }

  .errorMessage {
    color: red;
  }
}

.submitButton {
  width: 10em;
  font-size: 1.25em;

  @include smartphone-view {
    width: 100%;
  }
}
