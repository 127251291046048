@import "src/assets/mixins";

.form {
  margin-bottom: 1em;

  .formSection {
    display: flex;
    flex-direction: column;
    margin-bottom: 0em;
    min-width: 13em;
    flex-grow: 1;

    h4 {
      margin-bottom: 0.5em;
    }

    .otherInfoInput {
      width: 100%;
      height: 100px;
      resize: vertical;
    }
  }
}

.submitButton {
  width: 10em;
  font-size: 1.25em;

  @include smartphone-view {
    width: 100%;
  }
}
