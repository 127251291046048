@import 'src/assets/colors';

.aboutUs {
  img {
    margin-bottom: 1em;
    border: solid 1px $colorSecondaryDark;
    border-radius: 3px;
  }

  .pic {
    width: 100%;
  }

  .smallPicContainer {
    min-width: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-basis: 45%;

    margin-right: 0.5em;
    margin-left: 0.5em;
  }

  .picContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.5em;
    margin-right: -0.5em;
    align-items: flex-start;

    .picHigh {
      width: 50%;
      flex-basis: 30%;
      display: block;
      flex-grow: 1;
      margin-right: 0.5em;
      margin-left: 0.5em;
    }
  }

  .signature {
    color: $colorSecondary;
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
  }
}
