$colorPrimary: #FFFFB9;
$colorPrimaryLight: #FFE9B9;
$colorPrimaryDark: #FFE9B9;

$colorSecondary: #f26522;
$colorSecondaryLight: #ff9651;
$colorSecondaryExtraLight: #FFEFE5;
$colorSecondaryDark: #b83400;

$colorGrey1: #eee;
$colorGrey2: #000;
$colorGrey3: #cecece;
$colorGrey4: #000;
$colorGrey5: #000;

$colorBackground: #f5f5f5;
