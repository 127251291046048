@import "src/assets/colors";

.sidebar {

  .title {

  }

  .servicesWrapper {
    padding-left: 1em;
    margin-bottom: 1em;

    .service {
      display: flex;
      align-content: center;
      margin-bottom: 0.5em;

      svg {
        fill: $colorSecondary;
        margin: auto 10px auto 0;
        min-width: 24px;
      }
    }
  }

  .orderButton {
    display: block;
    text-align: center;
    width: 100%;
  }

  hr {
    margin: 1em 0;
  }

  .contactWrapper {

    .moreQuestionsFirstLine {
      margin-bottom: 0;
    }
    .moreQuestionsSecondLine {
      margin-bottom: 0.5em;
    }

    .phoneButton {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5em;

      svg {
        margin-right: 1em;
      }
    }

    .workingHours {
      display: flex;
      justify-content: space-between;
    }
  }

  .locationsWrapper {
    p{margin-bottom: 0;}
    display: flex;
    flex-wrap: wrap;
    .locationsTitle {
      width: 100%;
    }

    .surroundings {
      margin: auto 0 auto auto;
    }
  }
}
