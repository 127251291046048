@import "colors";
@import "clear";
@import "~hamburgers/_sass/hamburgers/hamburgers.scss";

@font-face {
  font-family: 'Noto Sans';
  src: local('Noto Sans'), url(./fonts/NotoSans-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  background-color: $colorBackground;
  font-size: 1.25em;

  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input[type="date"] {
  border-top-width: 2px;
  border-bottom-width: 2px;
  padding-top: 1px;
  padding-bottom: 1px;
  font-family: inherit;
  background-color: white;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  margin-inline-start: 0;
}

input[type="date"]:after {
  content: "";
  width: 0.9em;
  height: 1.2em;
  color: #555;
  padding: 0 5px;
  background-image: -webkit-image-set(url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNSIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBmaWxsPSJXaW5kb3dUZXh0IiBkPSJNMjAgM2gtMVYxaC0ydjJIN1YxSDV2Mkg0Yy0xLjEgMC0yIC45LTIgMnYxNmMwIDEuMS45IDIgMiAyaDE2YzEuMSAwIDItLjkgMi0yVjVjMC0xLjEtLjktMi0yLTJ6bTAgMThINFY4aDE2djEzeiIvPjxwYXRoIGZpbGw9Im5vbmUiIGQ9Ik0wIDBoMjR2MjRIMHoiLz48L3N2Zz4=) 1x);
  background-repeat: no-repeat;
  background-size: cover;
}

h1 {
  margin-top: 0;
  font-size: 1.75em;
  //margin-block-start: 0;
}

h4 {
  margin-top: 0;
  margin-bottom: 0.5em;
  font-size: 1.15em;
}

textarea {
  font-family: inherit;
  font-size: inherit;
  box-sizing: border-box;
}

hr {
  flex-grow: 1;
  flex-basis: 100%;
}

input {
  box-sizing: border-box;
}
