@import "src/assets/mixins";
@import "src/assets/colors";

.app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .coolio {
    display: flex;
    flex-wrap: wrap;
    padding: 2em 3em;
    justify-content: center;
    background-color: $colorBackground;

    @include tablet-view {
      padding: 1em;
    }

    @include smartphone-view {
      padding: 0;
    }

    .pageContent {
      max-width: 1000px;
      margin-right: 1em;
      flex: 1 1 300px;

      @include tablet-view-and-down {
        margin-right: 0;
      }
    }

    .separator {
      display: none;
      margin-top: 1em;
      margin-bottom: 1em;

      @include tablet-view-and-down {
        display: block;
      }
    }

    .sidebar {
      min-width: 15em;
      flex: 1 1 350px;
      border-radius: 5px;
      margin-bottom: 1em;

      @include tablet-view-and-down {
        flex-basis: 100%;
      }

      @include desktop-view-and-up {
        flex-grow: 0;
      }
    }
  }
}
