@import "src/assets/colors";
@import "src/assets/mixins";

.HomePage {
  text-align: center;

  .title {
    font-size: 3em;
    font-weight: 500;
    font-family: 'Alegreya SC', serif;
    color: $colorSecondary;
    //text-shadow: 0 0 20px $colorSecondaryLight;
    //-webkit-text-stroke: 1px $colorSecondaryDark;

    @include desktop-view {
      font-size: 2.5em;
    }

    @include tablet-view {
      font-size: 2em;
    }

    @include smartphone-view {
      font-size: 1.5em;
    }
  }

  img {
    width: 90%;
    border-radius: 3px;
    border: solid 1px $colorSecondaryDark;
    margin: -10px 0 20px 0;
  }

  p {
    font-weight: 700;
  }
}
