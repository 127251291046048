@import "src/assets/colors.scss";

.contactInfo {
  p {
    margin: 0;
  }

  .emailLink {
    color: $colorSecondary;

    &:hover {
      text-decoration: underline;
    }
  }

  .marginBottom {
    margin-bottom: 1em;
  }
}
