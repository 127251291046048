@import "src/assets/colors";
@import "src/assets/mixins";

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 80px;
  height: 80px;
  background-color: #777;
  margin-top: auto;
  border-top: 2px solid $colorSecondary;
  color: white;

  @include smartphone-view {
    flex-direction: column;
    height: auto;
    min-height: auto;
    padding: 1em 0;
  }

  .link {
    &:hover {
      text-decoration: underline;
    }
  }

  .link + .link {
    margin-left: 2em;

    @include smartphone-view {
      margin-left: 0;
      margin-top: 1em;
    }
  }
}
