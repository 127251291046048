@import "colors";
@import "mixins";

.buttonPrimary {
  padding: 0.5em;
  background-color: $colorSecondary;
  color: white;
  fill: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background-color: $colorSecondaryDark;
  }
}

.buttonSecondary {
  padding: 0.5em;
  background-color: transparent;
  border: 1px solid $colorSecondary;
  border-radius: 3px;
  cursor: pointer;
  color: $colorSecondary;
  fill: $colorSecondary;
  box-sizing: border-box;

  &:hover {
    background-color: $colorSecondaryExtraLight;
  }
}

.buttonPrimary, .buttonSecondary {
  transition: background-color 100ms linear;
}

.box {
  border: 1px solid #cecece;
  border-radius: 3px;
  padding: 1em;
  margin-bottom: 1em;
  background-color: white;

  &:last-of-type {
    margin-bottom: 0;
  }
}
